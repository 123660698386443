<template>
    <v-dialog v-model="isVisible" persistent max-width="480">
        <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
            <v-card v-if="passportViewModel">
                <v-card-title class="headline">
                    {{ $t('views.account.editMobilePhoneDialog.title') }}
                </v-card-title>
                <v-card-text>
                    <v-container class="pa-0">
                        <v-row no-gutters>
                            <v-col cols="6">
                                <v-select
                                    slot="prepend"
                                    v-model="model.country"
                                    :items="countries"
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('views.account.editMobilePhoneDialog.country')"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="model.mobilePhone"
                                    :rules="[rules.required]"
                                    :validate-on-blur="false"
                                    :error="hasError('mobilePhone')"
                                    :error-messages="getError('mobilePhone')"
                                    :label="$t('views.account.editMobilePhoneDialog.mobilePhone')"
                                    :counter="phoneNumberLength"
                                    name="mobilePhone"
                                    @change="resetError('mobilePhone')"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="model.captcha"
                                    :rules="[rules.required]"
                                    :validate-on-blur="false"
                                    :error="hasError('captcha')"
                                    :error-messages="getError('captcha')"
                                    :label="$t('views.account.editMobilePhoneDialog.captcha')"
                                    :counter="passportViewModel.captchaOptions.length"
                                    :disabled="captchaInputDisabled"
                                    name="captcha"
                                    autocomplete="off"
                                    @change="resetError('captcha')"
                                >
                                    <v-btn slot="append" color="primary" text :disabled="sendCaptchaButtonDisabled" @click="sendSmsCaptcha">
                                        {{ countDownSeconds>0 ? `(${countDownSeconds}s)` : $t('views.account.editMobilePhoneDialog.sendCaptcha') }}
                                    </v-btn>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancel()" :disabled="isSubmitting">
                        {{ $t('common.cancel') }}
                    </v-btn>
                    <v-btn type="submit" color="primary" :disabled="submitButtonDisabled">
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import FormMixins from '@/mixins/FormMixins';
import countries from '@/models/countries';
import utils from '@/utils';
import { getPassportViewModel, sendUpdatePersonalMobilePhoneCaptcha, updatePersonalMobilePhone } from '@/api';

export default {
    mixins: [FormMixins],
    data () {
        return {
            isVisible: false,
            isSubmitting: false,

            passportViewModel: null,

            // 表单
            isFormValid: false,
            model: {
                country: null,
                mobilePhone: '',
                captcha: '',
                captchaId: null
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            },

            // 验证码倒计时
            countDownSeconds: 0,

            $resolve: null,
            $reject: null
        };
    },
    computed: {
        countries () {
            return _(countries)
                .map((c) => {
                    return {
                        value: c.value,
                        text: this.$t(`models.countries.${c.value}`) + ` (+${c.callingCode})`,
                        phoneNumberLength: c.phoneNumberLength
                    };
                })
                .value();
        },
        phoneNumberLength () {
            return _.find(countries, (c) => c.value === this.model.country).phoneNumberLength;
        },
        captchaInputDisabled () {
            var disabled = this.model.mobilePhone.length === 0 ||
                this.model.mobilePhone.length !== this.phoneNumberLength;
            return disabled;
        },
        sendCaptchaButtonDisabled () {
            return this.isSubmitting || this.captchaInputDisabled || this.countDownSeconds > 0;
        },
        submitButtonDisabled () {
            return this.isSubmitting ||
                !(
                    (this.model.mobilePhone && this.model.mobilePhone.length === this.phoneNumberLength) &&
                    (this.model.captcha && this.model.captcha.length === this.passportViewModel.captchaOptions.length) &&
                    this.model.captchaId
                );
        }
    },
    watch: {
        currentCulture (val) {
            this.resetFormErrors();
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        }
    },
    methods: {
        show () {
            return new Promise(async (resolve, reject) => {
                this.$resolve = resolve;
                this.$reject = reject;
                this.countDownSeconds = 0;

                // 读取模型
                var account = await this.$store.dispatch('session/getAccountInfo');
                if (account.mobilePhone) {
                    var mt = account.mobilePhone.match(/^\+(\d+)\-(\d+)$/);
                    var callingCode = parseInt(mt[1], 10);
                    var mobilePhone = mt[2];

                    this.model.country = _.find(countries, c => c.callingCode === callingCode).value;
                    this.model.mobilePhone = mobilePhone;
                } else {
                    this.model.country = this.passportViewModel.smsOptions.defaultCountry;
                    this.model.mobilePhone = '';
                }
                this.model.captcha = '';
                this.model.captchaId = null;

                this.isVisible = true;
                await this.$nextTick();
                this.resetFormErrors();
                this.$refs.form.resetValidation();
            });
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults({}, this.model);

            try {
                // console.log(postData);
                await updatePersonalMobilePhone(postData);
                this.$resolve(postData);
                this.isVisible = false;
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        cancel () {
            this.$reject(utils.error.userCancelled());
            this.isVisible = false;
        },
        async sendSmsCaptcha () {
            try {
                this.model.captchaId = await sendUpdatePersonalMobilePhoneCaptcha({
                    country: this.model.country,
                    mobilePhone: this.model.mobilePhone
                });

                this.countDownSeconds = 60;
                var countDown = () => {
                    if (--this.countDownSeconds <= 0) {
                        clearInterval(countDownId);
                    }
                };
                var countDownId = setInterval(countDown, 1000);
            } catch (ex) {
                this.$toast.error(ex.message);
            }
        }
    },
    async created () {
        this.passportViewModel = await getPassportViewModel({ returnUrl: null });
        this.model.country = this.passportViewModel.smsOptions.defaultCountry;
    }
};
</script>
