<template>
    <v-dialog v-model="isVisible" persistent max-width="480">
        <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
            <v-card v-if="passportViewModel">
                <v-card-title class="headline">
                    {{ $t('views.account.editEmailDialog.title') }}
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="model.email"
                        :rules="[rules.required, rules.email]"
                        :validate-on-blur="false"
                        :error="hasError('email')"
                        :error-messages="getError('email')"
                        :label="$t('views.account.editEmailDialog.email')"
                        name="email"
                        @change="resetError('email')"
                    />

                    <v-text-field
                        v-model="model.captcha"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        :error="hasError('captcha')"
                        :error-messages="getError('captcha')"
                        :label="$t('views.account.editEmailDialog.captcha')"
                        name="captcha"
                        autocomplete="off"
                        @change="resetError('captcha')"
                    >
                        <v-btn slot="append" color="primary" text :disabled="sendCaptchaButtonDisabled" @click="sendEmailCaptcha">
                            {{ countDownSeconds>0 ? `(${countDownSeconds}s)` : $t('views.account.editEmailDialog.sendCaptcha') }}
                        </v-btn>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancel()" :disabled="isSubmitting">
                        {{ $t('common.cancel') }}
                    </v-btn>
                    <v-btn type="submit" color="primary" :disabled="submitButtonDisabled">
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import FormMixins from '@/mixins/FormMixins';
import utils from '@/utils';
import { getPassportViewModel, sendUpdatePersonalEmailCaptcha, updatePersonalEmail } from '@/api';

export default {
    mixins: [FormMixins],
    data () {
        return {
            isVisible: false,
            isSubmitting: false,

            passportViewModel: null,

            // 表单
            isFormValid: false,
            model: {
                email: '',
                captcha: '',
                captchaId: null
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required'),
                email: (value) => (value && utils.validate.isEmail(value)) || this.$t('validation.invalidEmailFormat')
            },

            // 验证码倒计时
            countDownSeconds: 0,

            $resolve: null,
            $reject: null
        };
    },
    computed: {
        captchaInputDisabled () {
            return !(this.model.email && utils.validate.isEmail(this.model.email));
        },
        sendCaptchaButtonDisabled () {
            return this.isSubmitting || this.captchaInputDisabled || this.countDownSeconds > 0;
        },
        submitButtonDisabled () {
            return this.isSubmitting ||
                !(
                    (this.model.email && utils.validate.isEmail(this.model.email)) &&
                    (this.model.captcha && this.model.captcha.length === this.passportViewModel.captchaOptions.length) &&
                    (this.model.captchaId)
                );
        }
    },
    watch: {
        currentCulture (val) {
            this.resetFormErrors();
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        }
    },
    methods: {
        show () {
            return new Promise(async (resolve, reject) => {
                this.$resolve = resolve;
                this.$reject = reject;
                this.countDownSeconds = 0;

                // 读取模型
                var account = await this.$store.dispatch('session/getAccountInfo');
                this.model.email = account.email;
                this.model.captcha = '';
                this.model.captchaId = null;

                // 重置
                this.isVisible = true;
                await this.$nextTick();
                this.resetFormErrors();
                this.$refs.form.resetValidation();
            });
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults({}, this.model);

            try {
                await updatePersonalEmail(postData);
                this.$resolve(postData);
                this.isVisible = false;
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        cancel () {
            this.$reject(utils.error.userCancelled());
            this.isVisible = false;
        },
        async sendEmailCaptcha () {
            try {
                this.model.captchaId = await sendUpdatePersonalEmailCaptcha({
                    email: this.model.email
                });

                this.countDownSeconds = 60;
                var countDown = () => {
                    if (--this.countDownSeconds <= 0) {
                        clearInterval(countDownId);
                    }
                };
                var countDownId = setInterval(countDown, 1000);
            } catch (ex) {
                this.$toast.error(ex.message);
            }
        }
    },
    async created () {
        this.passportViewModel = await getPassportViewModel({ returnUrl: null });
    }
};
</script>
