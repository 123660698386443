<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="value"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            />
        </template>
        <v-date-picker
            ref="picker"
            v-model="value"
            :max="new Date().toISOString().substr(0, 10)"
            min="1900-01-01"
            @change="save"
        />
    </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
    model: {
        prop: 'date',
        event: 'change'
    },
    props: {
        date: {
            type: [Date, String]
        },
        typeOfDate: {
            type: [String],
            default: 'string'
        },
        label: String
    },
    data () {
        return {
            value: '',
            menu: false
        };
    },
    watch: {
        menu (val) {
            // 当显示菜单 且 未设置日期 时，将选择器切换为年份模式
            val && !this.value && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
        },
        date () {
            this.value = this.date ? dayjs(this.date).format('YYYY-MM-DD') : '';
        },
        value (newValue, oldValue) {
            var newDate = dayjs(newValue);
            if (newDate.isSame(dayjs(oldValue))) {
                return;
            }

            var val;
            switch (this.typeOfDate.toLowerCase()) {
            case 'string': val = newDate.toISOString(); break;
            default: val = newDate.toDate(); break;
            }
            this.$emit('change', val);
        }
    },
    methods: {
        save (date) {
            this.$refs.menu.save(date);
        }
    }
};
</script>
