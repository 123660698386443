export default [
    {
        value: 'china',
        callingCode: 86,
        phoneNumberLength: 11
    },
    {
        value: 'malaysia',
        callingCode: 60,
        phoneNumberLength: 10
    }
];
