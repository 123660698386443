export default [
    {
        value: 'unknown'
    },
    {
        value: 'surname_GivenName',
        displayFormat: '{surname}{givenName}'
    },
    {
        value: 'surname_Space_GivenName',
        displayFormat: '{surname} {givenName}'
    },
    {
        value: 'surname_Dot_GivenName',
        displayFormat: '{surname}.{givenName}'
    },
    {
        value: 'givenName_Space_Surname',
        displayFormat: '{givenName} {surname}'
    }
];
