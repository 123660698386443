<template>
    <div class="flex-grow-1">
        <div class="display-1 mb-3">{{ $t('views.account.profile.title') }}</div>
        <!-- class="d-flex flex-column flex-sm-row pa-4" -->
        <v-container class="pa-0 my-3">
            <v-row dense>
                <v-col cols="12" xl="6">
                    <v-sheet class="d-flex pa-2 rounded">
                        <div>
                            <v-skeleton-loader v-if="isLoading" type="avatar" />
                            <div v-else class="d-flex flex-column">
                                <v-avatar size="64" class="elevation-3 mx-auto">
                                    <v-img v-if="account.avatar" :src="account.avatar" />
                                    <v-icon v-else dark size="64">mdi-account-circle</v-icon>
                                </v-avatar>
                                <v-btn class="mt-1" block small @click="handleEditAvatar">{{ $t('views.account.profile.editAvatar') }}</v-btn>
                            </div>
                        </div>
                        <div class="flex-grow-1 ml-2">
                            <!-- 登录ID -->
                            <v-skeleton-loader v-if="isLoading" type="list-item-two-line" />
                            <EditNameIdentifierForm
                                v-else-if="editingField==='nameIdentifier'"
                                v-model="account.nameIdentifier"
                                @submit="editingField = null"
                                @cancel="editingField = null"
                            />
                            <v-input
                                v-else
                                :messages="[$t('views.account.profile.nameIdentifierHint')]"
                                prepend-icon="mdi-passport"
                                :append-icon="account.nameIdentifier ? 'mdi-square-edit-outline' : null"
                                class="v-input-display"
                                @click:append="editingField='nameIdentifier'"
                            >
                                {{ $t('models.account.nameIdentifier') }}:
                                <template v-if="account.nameIdentifier">
                                    {{ account.nameIdentifier }}
                                </template>
                                <template v-else>
                                    <a class="text-decoration-none" @click="editingField='nameIdentifier'">{{ $t('common.unset') }}</a>
                                </template>
                            </v-input>

                            <!-- 昵称 -->
                            <v-skeleton-loader v-if="isLoading" type="list-item-two-line" />
                            <EditNameForm
                                v-else-if="editingField==='name'"
                                v-model="account.name"
                                @submit="editingField = null"
                                @cancel="editingField = null"
                            />
                            <v-input
                                v-else
                                :messages="[$t('views.account.profile.nameHint')]"
                                prepend-icon="mdi-account"
                                :append-icon="account.name ? 'mdi-square-edit-outline' : null"
                                class="v-input-display"
                                @click:append="editingField='name'"
                            >
                                {{ $t('models.account.name') }}:
                                <template v-if="account.name">
                                    {{ account.name }}
                                </template>
                                <template v-else>
                                    <a class="text-decoration-none" @click="editingField='name'">{{ $t('common.unset') }}</a>
                                </template>
                            </v-input>

                            <!-- 手机号码 -->
                            <v-skeleton-loader v-if="isLoading" type="list-item" />
                            <v-input
                                v-else
                                prepend-icon="mdi-cellphone"
                                append-icon="mdi-square-edit-outline"
                                hide-details="auto"
                                class="v-input-display"
                                @click:append="handleEditMobilePhone()"
                            >
                                {{ $t('models.account.mobilePhone') }}:
                                <template v-if="account.mobilePhone">
                                    {{ account.mobilePhone }}
                                </template>
                                <template v-else>
                                    <a class="text-decoration-none" @click="handleEditMobilePhone()">{{ $t('common.unset') }}</a>
                                </template>
                            </v-input>

                            <!-- 电子邮箱 -->
                            <v-skeleton-loader v-if="isLoading" type="list-item" />
                            <v-input
                                v-else
                                prepend-icon="mdi-email"
                                append-icon="mdi-square-edit-outline"
                                hide-details="auto"
                                class="v-input-display"
                                @click:append="handleEditEmail()"
                            >
                                {{ $t('models.account.email') }}:
                                <template v-if="account.email">
                                    {{ account.email }}
                                </template>
                                <template v-else>
                                    <a class="text-decoration-none" @click="showEditEmailForm()">{{ $t('common.unset') }}</a>
                                </template>
                            </v-input>
                        </div>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xl="6">
                    <v-sheet class="pa-2 rounded">
                        <EditAccountInfoForm :isLoading="isLoading" :account="account" @submit="reloadAccountInfo" />
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
        <EditMobilePhoneDialog ref="dlgEditMobilePhone" />
        <EditEmailDialog ref="dlgEditEmail" />
    </div>
</template>

<style scoped>
.v-input-display {
    margin-top: 24px;
    margin-bottom: 24px;
}
.v-input-display.v-input--hide-details {
    align-items: center;
}
.v-field--editing {
    align-items: center;
}
</style>

<script>
import EditNameIdentifierForm from './EditNameIdentifierForm';
import EditNameForm from './EditNameForm';
import EditMobilePhoneDialog from './EditMobilePhoneDialog';
import EditEmailDialog from './EditEmailDialog';
import EditAccountInfoForm from './EditAccountInfoForm';

export default {
    components: {
        EditNameIdentifierForm,
        EditNameForm,
        EditMobilePhoneDialog,
        EditEmailDialog,
        EditAccountInfoForm
    },
    data () {
        return {
            isLoading: false,
            account: null,
            editingField: null
        };
    },
    watch: {
    },
    methods: {
        async handleEditAvatar () {
            // var accountInfo = await this.$store.dispatch('session/getAccountInfo');
            // console.log(this.account === accountInfo, accountInfo);
            // accountInfo.avatar = '/images/avatars/avatar1.svg';
            // this.$store.dispatch('session/updateAccountInfo', accountInfo);
        },
        async handleEditMobilePhone () {
            try {
                await this.$refs.dlgEditMobilePhone.show();
                await this.reloadAccountInfo();
            } catch (ex) {
                this.toastError(ex);
            }
        },
        async handleEditEmail () {
            try {
                await this.$refs.dlgEditEmail.show();
                await this.reloadAccountInfo();
            } catch (ex) {
                this.toastError(ex);
            }
        },
        async reloadAccountInfo () {
            await this.$store.dispatch('session/getAccountInfo', { force: true });
        }
    },
    async created () {
        this.isLoading = true;
        this.account = await this.$store.dispatch('session/getAccountInfo');
        this.isLoading = false;

        // TODO: 此处加入对布局的变更
        var layout = this.$root.findComponent((c) => c.$el.id === 'layout');
        // console.log(layout);
    }
};
</script>
