<template>
    <v-form ref="form" class="d-flex" @submit="submit">
        <v-text-field
            v-model="value"
            :rules="[rules.required]"
            class="flex-grow-1"
            :label="$t('models.account.name')"
            outlined
        />
        <div class="ml-1 mt-1">
            <v-btn type="submit" :loading="isSubmitting" :disabled="isSubmitting" color="primary">{{ $t('common.save') }}</v-btn>
            <v-btn :disabled="isSubmitting" class="ml-1" @click="cancel()">{{ $t('common.cancel') }}</v-btn>
        </div>
    </v-form>
</template>

<script>
import FormMixins from '@/mixins/FormMixins';
import { updatePersonalName } from '@/api';

export default {
    mixins: [FormMixins],
    model: {
        prop: 'name',
        event: 'submit'
    },
    props: {
        name: String
    },
    data () {
        return {
            isSubmitting: false,

            value: this.name,

            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            }
        };
    },
    methods: {
        async submit (e) {
            e.preventDefault();

            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = {
                name: this.value
            };

            try {
                await updatePersonalName(postData);
                this.$store.dispatch('session/updateAccountInfo', postData);
                this.$emit('submit', this.value);
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        cancel () {
            this.$emit('cancel');
        }
    }
};
</script>
