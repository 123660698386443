<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <v-text-field
            v-model="model.givenName"
            :rules="[rules.required]"
            :validate-on-blur="false"
            :error="hasError('givenName')"
            :error-messages="getError('givenName')"
            :label="$t('models.account.givenName')"
            name="givenName"
            @change="resetError('givenName')"
        />
        <v-text-field
            v-model="model.surname"
            :rules="[rules.required]"
            :validate-on-blur="false"
            :error="hasError('surname')"
            :error-messages="getError('surname')"
            :label="$t('models.account.surname')"
            name="surname"
            @change="resetError('surname')"
        />
        <div class="d-flex">
            <v-btn-toggle v-model="model.sex">
                <v-btn value="male">
                    <v-icon>mdi-human-male</v-icon>
                </v-btn>
                <v-btn value="female">
                    <v-icon>mdi-human-female</v-icon>
                </v-btn>
            </v-btn-toggle>
            <v-select class="flex-grow-1 ml-2"
                v-model="model.nameFormat"
                :items="nameFormats"
                item-text="text"
                item-value="value"
                :label="$t('models.account.nameFormat')"
            />
        </div>
        <DatePicker v-model="model.birthday" />
        <v-container v-show="isDirty" class="pa-0">
            <v-row v-if="$vuetify.breakpoint.mobile" dense>
                <v-col>
                    <v-btn type="submit" color="primary" block>
                        {{ $t('common.submit') }}
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="reset" block>
                        {{ $t('common.reset') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col class="text-center">
                    <v-btn type="submit" color="primary">
                        {{ $t('common.submit') }}
                    </v-btn>
                    <v-btn @click="reset" class="ml-2">
                        {{ $t('common.reset') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import _ from 'lodash';
import utils from '@/utils';
import FormMixins from '@/mixins/FormMixins';
import DatePicker from '@/components/DatePicker';
import nameFormats from '@/models/nameFormats';
import { updatePersonalAccountInfo } from '@/api';

export default {
    mixins: [FormMixins],
    components: {
        DatePicker
    },
    props: {
        isLoading: Boolean,

        account: Object
    },
    data () {
        return {
            isSubmitting: false,

            isDirty: false,

            isFormValid: false,
            model: {
                givenName: '', // this.account.givenName,
                surname: '', // this.account.surname,
                nameFormat: 'unknown', // this.account.nameFormat,
                sex: 'unknown', // this.account.sex,
                birthday: null // this.account.birthday
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            }
        };
    },
    computed: {
        nameFormats () {
            return _(nameFormats)
                .map((item) => {
                    var text = this.model.surname && this.model.givenName && item.displayFormat
                        ? utils.string.format(item.displayFormat, this.model)
                        : this.$t(`models.nameFormats.${item.value}`);
                    return {
                        value: item.value,
                        text: text,
                        displayFormat: item.displayFormat
                    };
                })
                .value();
        }
    },
    watch: {
        account: {
            deep: true,
            handler (val) {
                this.reset();
            }
        },
        model: {
            deep: true,
            handler (val) {
                this.isDirty = true;
            }
        }
    },
    methods: {
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults({}, this.model);

            try {
                await updatePersonalAccountInfo(postData);
                this.$emit('submit', postData);
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        async reset () {
            var account = this.account;
            for (var prop in this.model) {
                this.$set(this.model, prop, account[prop]);
            }
            await this.$nextTick();
            this.isDirty = false;
        }
    }
};
</script>
